import React, { useCallback, useContext } from 'react';
import Text, { TextTypes } from 'ni-ui/text';
import COLORS from 'ni-ui/colors';
import httpClient from 'ni-ui/httpClient';
import { Col } from 'ni-ui/layout';
import styles from './styles.scss';
import quickResponseIcon from '../../../images/quick_response.svg';
import { usePaymentMethodSetter } from '../payment-methods';
import { AppContext } from '../../../app-context';

const { quickResponseContainer, textAndIconContainer } = styles;


function UkhesheScanToPay() {
  const {
    orderDetails,
    outletRef,
    orderRef,
    code,
    setContextState,
  } = useContext(AppContext);

  const onClickPayNow = useCallback(async () => {
    try {
      const { data } = await httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/ukheshe/scan-to-pay`, {
        callbackUrl: `${window.origin}?code=${code}`
      });
      if (data.redirectUrl) {
        window.location.assign(data.redirectUrl);
      }
    } catch (error) {
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: 'FAILED'
        }
      });
    }
  }, [orderDetails, outletRef, orderRef, code]);

  usePaymentMethodSetter({ onClickPayNow, disablePayNow: false, id: 'UKHESHE' });

  return (
    <div className={quickResponseContainer}>
      <Col gutter={false} span={4} sm={7} md={4}>
        <div className={textAndIconContainer}>
          <img src={quickResponseIcon} alt="qrIcon" />
          <Text
            textKey="UKHESHE_SCAN_TO_PAY"
            textColor={COLORS.GREY_VERY_DARK}
            type={TextTypes.MENU_LEVEL_2}
          />
        </div>
      </Col>
    </div>
  );
}

export default UkhesheScanToPay;
