/* eslint no-restricted-syntax: 0 */
import React, { Fragment } from 'react';
import { arrayOf, oneOfType, shape, node, string } from 'prop-types';
import get from 'lodash/get';
import ThreeDsFingerprintingFrame from './frictionless-frame';
import ThreeDsChallengeFrame from './threeds-challenge-frame';

const ThreeDSTwo = ({ state, children, threeDsData }) => (
  <Fragment>
    {get(threeDsData, 'transStatus') === 'C' ?
      <ThreeDsChallengeFrame threeDsData={threeDsData} />
      :
      <Fragment>
        {state === 'AWAIT_3DS_2' && <ThreeDsFingerprintingFrame threeDsData={threeDsData} />}
        {children}
      </Fragment>}
  </Fragment>
);

ThreeDSTwo.propTypes = {
  state: string.isRequired,
  threeDsData: shape({
    threeDSServerTransID: string.isRequired
  }),
  children: oneOfType([node, arrayOf(node)]).isRequired,
};

ThreeDSTwo.defaultProps = {
  threeDsData: null,
};

export default ThreeDSTwo;
