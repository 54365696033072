import React, { useContext, useState, Fragment, Suspense, useMemo, useEffect, lazy, useCallback } from 'react';
import { Option } from 'ni-ui/radio';
import get from 'lodash/get';
import Colors from 'ni-ui/colors';
import { Alert, NotificationType } from 'ni-ui/notification';
import { Row, Col } from 'ni-ui/layout';
import Text, { TextTypes } from 'ni-ui/text';
import { PropTypes } from 'prop-types';
import httpClient from 'ni-ui/httpClient';
import { AppContext } from '../../../app-context';
import styles from './styles.scss';
import { usePaymentMethodSetter } from '../payment-methods';
import SplitPaymentCard from './split-payment-card';
import { minorToMajorUnitsFromCurrencyCode } from '../../../utils';
import cardLogo from '../../../images/card.svg';
import dashCircle from '../../../images/dash-circle.svg';
import plusCircle from '../../../images/plus-circle.svg';

const Card = lazy(() => import('../card/card'));

const SplitPayment = ({ order }) => {
  let cards = [];
  if (order.savedCards) {
    cards = cards.concat(order.savedCards);
  } else if (order.savedCard) {
    cards = cards.concat(order.savedCard);
  }

  const { orderDetails, setContextState } = useContext(AppContext);
  const [splitPaymentCards, setSplitPaymentCards] = useState(cards);

  const { currencyCode, value } = orderDetails.order.amount;
  const [cardsSelected, setCardsSelected] = useState([]);
  const [isSubChoiceChecked, setSelectedSubChoice] = useState(false);
  const [errorTextKey, setErrorTextKey] = useState('');
  const totalAmount = useMemo(() => cardsSelected.reduce(
    (amount, card) => (card.amount ? card.amount.value + amount : amount),
    0
  ), [cardsSelected]);

  const splitPaymentCardsLimit = get(orderDetails, 'splitPaymentCardsLimit');
  const [remainingAmount, setRemainingAmount] = useState(orderDetails.order.amount);
  const calculatedAmount = useMemo(() => value - totalAmount, [cardsSelected]);

  useEffect(() => {
    setRemainingAmount(calculatedAmount >= 0 ? calculatedAmount : 0);
  }, [cardsSelected]);
  useEffect(() => {
    if (splitPaymentCards.length >= splitPaymentCardsLimit) {
      setSelectedSubChoice(false);
    }
  }, [splitPaymentCards]);

  const includeCsc = card => !card.recaptureCsc || JSON.parse(card.recaptureCsc);

  const areCvvsValid = cardsSelected
    .every(selectedCard => !includeCsc(selectedCard) ||
      (!!selectedCard.cvv && selectedCard.cvv.length === 3));

  const onClickPayNow = useCallback(async () => {
    const { outletId, reference } = orderDetails.order;
    const splitPaymentBody = cardsSelected.map((card) => {
      const includeCvv = includeCsc(card);
      return {
        cardholderName: card.cardholderName,
        ...(card.isAddedCard && { pan: card.pan }),
        expiry: card.expiry,
        ...(includeCvv && { cvv: card.cvv }),
        ...(card.isAddedCard && { name: card.scheme }),
        ...(card.isAddedCard && { saveMyCard: card.recaptureCsc }),
        amount: card.amount,
        ...(!card.isAddedCard && { cardToken: card.cardToken })
      };
    });

    const { data } = await httpClient.post(
      `/api/outlets/${outletId}/orders/${reference}/payments/${orderDetails.order.paymentReference}/split-payment`,
      { cards: splitPaymentBody }
    );
    setContextState({
      orderDetails: {
        ...orderDetails,
        state: data.state,
        order: data.order,
      },
    });
  }, [orderDetails.order, cardsSelected]);

  const amountOnSingleCard = cardsSelected.some(card => card.amount &&
    card.amount.value >= Number(value));
  const addedCardsCount = splitPaymentCards.filter(card => card.isAddedCard).length;
  const invalidSelectedCards = cardsSelected.some(card => !card.amount || card.amount.value === 0);

  const selectedPaymentMethodName = usePaymentMethodSetter({
    onClickPayNow,
    disablePayNow: (!!calculatedAmount) || !areCvvsValid ||
    amountOnSingleCard || invalidSelectedCards,
    id: 'SPLIT_PAYMENT'
  });
  const CheckBoxElement = (
    <div
      tabIndex={0}
      role="button"
      onClick={() => setSelectedSubChoice(!isSubChoiceChecked)}
      onKeyPress={() => setSelectedSubChoice(!isSubChoiceChecked)}
    >
      <img src={!isSubChoiceChecked ? plusCircle : dashCircle} alt="Add new card" width="27" height="27" />
    </div>
  );

  useEffect(() => {
    setSplitPaymentCards(splitPaymentCards.map(card => ({ ...card, cvv: '' })));
    setCardsSelected([]);
  }, [selectedPaymentMethodName]);

  const balance = minorToMajorUnitsFromCurrencyCode(remainingAmount, currencyCode);

  const removeCard = (card) => {
    setSplitPaymentCards(splitPaymentCards.filter(selectedCard =>
      selectedCard.cardToken !== card.cardToken));
    setCardsSelected(cardsSelected.filter(selectedCard =>
      selectedCard.cardToken !== card.cardToken));
  };

  useEffect(() => {
    switch (true) {
      case calculatedAmount < 0:
        setErrorTextKey('SPLIT_PAYMENT_ADJUSTMENT_ERROR');
        break;
      case amountOnSingleCard:
        setErrorTextKey('TOTAL_AMOUNT_ON_SINGLE_CARD_ERROR');
        break;
      default:
        setErrorTextKey('');
    }
  }, [calculatedAmount, amountOnSingleCard, cardsSelected, splitPaymentCards]);

  const validateAndSetSplitPaymentCards = (card) => {
    const cardAlreadyPresent = splitPaymentCards.some(cardDetails => cardDetails.pan === card.pan);
    if (cardAlreadyPresent) {
      setErrorTextKey('CARD_ALREADY_ADDED');
    } else {
      setSplitPaymentCards([...splitPaymentCards, card]);
    }
  };

  return (
    <Fragment>
      <Row key={orderDetails.order.paymentReference} gutter={false}>
        <Col gutter={false}>
          <Option label="split-payment" id="SPLIT_PAYMENT">
            <div className={styles.headerContainer}>
              <Col gutter={false} span={4} sm={7} md={4}>
                <div className={styles.holder}>
                  <img src={cardLogo} alt="Cards" />
                  <Text
                    textKey="SPLIT_PAYMENT"
                    textColor={Colors.GREY_VERY_DARK}
                    type={TextTypes.MENU_LEVEL_2}
                  />
                </div>
              </Col>
            </div>
          </Option>
          <Suspense fallback={<h1>Loading..</h1>}>
            {selectedPaymentMethodName === 'SPLIT_PAYMENT' && (
              <Fragment>
                {splitPaymentCards.map((card, index) => (
                  <SplitPaymentCard
                    key={card.cardToken}
                    card={card}
                    index={index}
                    cardsSelected={cardsSelected}
                    setCardsSelected={setCardsSelected}
                    removeCard={() => removeCard(card)}
                    balance={remainingAmount}
                  />
                ))}

                <Row>
                  <div className={styles.balanceAdjustmentWrapperStyle}>
                    <Col span={8} md={12} sm={12}>
                      { errorTextKey && (
                        <Alert
                          type={NotificationType.ERROR}
                          textKey={errorTextKey}
                        />
                      )}
                    </Col>

                    <Col span={4} md={12} sm={12}>
                      {selectedPaymentMethodName === 'SPLIT_PAYMENT' &&
                        splitPaymentCards.length > 0 && (
                        <div className={styles.remainingAmountText}>
                          <Text
                            textKey="SPLIT_PAYMENT_REMAINING"
                            values={{
                              remainingAmount: balance,
                              currency: currencyCode,
                            }}
                            textColor={Colors.GREY_VERY_DARK}
                            type={TextTypes.MENU_LEVEL_1}
                          />
                        </div>
                      )}
                    </Col>
                  </div>
                </Row>

                <div
                  className={`${styles.splitPaymentCardCheckbox}  ${
                    styles.splitPaymentCard
                  } ${// rest of unselected cards  - No. of added cards
                    (cardsSelected.length >= splitPaymentCardsLimit ||
                      splitPaymentCardsLimit - addedCardsCount <= 0) &&
                    styles.splitPaymentAddCardDisabled
                  } `}
                >
                  <Card
                    id="SPLIT_PAYMENT"
                    isSubChoice
                    inputType="checkbox"
                    isSubChoiceChecked={isSubChoiceChecked}
                    CheckBoxElement={CheckBoxElement}
                    cardHeaderTitle="USE_NEW_CARD"
                    setSplitPaymentCards={validateAndSetSplitPaymentCards}
                    setSelectedSubChoice={setSelectedSubChoice}
                  />
                </div>

              </Fragment>
            )}
          </Suspense>
        </Col>
      </Row>
    </Fragment>
  );
};

SplitPayment.defaultProps = {};

SplitPayment.propTypes = {
  order: PropTypes.shape({}).isRequired,
};

export default SplitPayment;
