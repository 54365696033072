import React, { useContext, useCallback } from 'react';

import Text, { TextTypes } from 'ni-ui/text';
import httpClient from 'ni-ui/httpClient';
import COLORS from 'ni-ui/colors';

import styles from './styles.scss';
import { AppContext } from '../../../app-context';
import { usePaymentMethodSetter } from '../payment-methods';
import ApplePayIcon from '../../../images/apple_pay.png';
import { getPaymentRequest } from './helper';
import { isKsaRegion } from '../../../utils';

const ApplePay = () => {
  const {
    orderDetails,
    outletRef,
    orderRef,
    setContextState,
  } = useContext(AppContext);


  const onClickPayNow = useCallback(() => {
    let outletName = 'Outlet';
    const paymentRequest = getPaymentRequest(orderDetails);

    const session = new window.ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = async () => {
      httpClient.get(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/apple-pay/validate-session`)
        .then((response) => {
          outletName = response.data.session.displayName;
          session.completeMerchantValidation(response.data.session);
        }).catch(() => {
          setContextState({
            orderDetails: {
              ...orderDetails,
              walletProcessingError: true,
              walletType: 'APPLE_PAY',
              state: 'REATTEMPT_STARTED'
            }
          });
        });
    };

    session.onpaymentmethodselected = () => {
      const update = {
        newTotal: {
          label: outletName,
          type: 'final',
          amount: +paymentRequest.total.amount,
        }
      };
      session.completePaymentMethodSelection(update);
    };

    session.oncancel = () => {
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: 'REATTEMPT_STARTED'
        }
      });
    };

    session.onpaymentauthorized = (event) => {
      httpClient.post(
        `/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/apple-pay/accept?isWebPayment=true`,
        event.payment.token.paymentData
      ).then((response) => {
        const { state } = response.data;
        const orderStates = ['AUTHORISED', 'CAPTURED', 'PURCHASED'];
        const allowedStates = isKsaRegion() ? [...orderStates, 'VERIFIED'] : orderStates;
        if (allowedStates.includes(state)) {
          session.completePayment({ status: 0 });
        } else {
          session.completePayment({ status: 1 });
        }
        setContextState({
          orderDetails: {
            ...orderDetails,
            state,
          }
        });
      }).catch(() => {
        session.completePayment({ status: 1 });
        setContextState({
          orderDetails: {
            ...orderDetails,
            state: 'REATTEMPT_STARTED'
          }
        });
      });
    };

    session.begin();
  }, []);

  usePaymentMethodSetter({ onClickPayNow, disablePayNow: false, id: 'APPLE_PAY' });
  return (
    <div className={styles.applePayContainer}>
      <Text
        textKey="APPLE_PAY"
        textColor={COLORS.GREY_VERY_DARK}
        type={TextTypes.MENU_LEVEL_2}
      />
      <div className={styles.iconPadding}>
        {<img src={ApplePayIcon} width="80px" alt="Apple Pay" />}
      </div>
    </div>
  );
};

export default ApplePay;
