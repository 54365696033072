import React from 'react';
import PropTypes from 'prop-types';
import { keys, includes } from 'lodash';
import pciLogo from '../../images/pci_dss.png';
import visaSecureLogo from '../../images/visa.png';
import masterCardSecureLogo from '../../images/mastercard.png';
import emvcoLogo from '../../images/emvco.png';
import amexSecureLogo from '../../images/amex.png';
import dinersSecureLogo from '../../images/diners.jpg';
import jcbSecureLogo from '../../images/jcb.png';
import styles from './styles.scss';

const icons = {
  MADA: <img src={emvcoLogo} alt="EMV ProtectBuy" key="5" />,
  VISA: <img src={visaSecureLogo} alt="Verified by VISA" key="1" />,
  MASTERCARD: <img src={masterCardSecureLogo} alt="MasterCard SecureCode" key="2" />,
  AMERICAN_EXPRESS: <img src={amexSecureLogo} alt="American Express Safe Key" key="3" />,
  DINERS_CLUB_INTERNATIONAL: <img src={dinersSecureLogo} alt="Diners Club ProtectBuy" key="4" />,
  JCB: <img src={jcbSecureLogo} alt="Jcb J/Secure" key="6" />,
};

const SecureIcons = ({ cards }) => {
  const supportedSecureIcons = keys(icons)
    .filter(key => includes(cards, key))
    .map(key => icons[key]);

  return (
    <div className={styles.securePayIcons}>
      <img src={pciLogo} alt="PCI DSS Compliant" />
      {supportedSecureIcons}
    </div>
  );
};

SecureIcons.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SecureIcons;
