
import AmericanExpress from 'ni-ui/icon/lib/cards/AmericanExpress';
import Visa from 'ni-ui/icon/lib/cards/Visa';
import Mada from 'ni-ui/icon/lib/cards/Mada';
import Jcb from 'ni-ui/icon/lib/cards/Jcb';
import Maestro from 'ni-ui/icon/lib/cards/Maestro';
import Master from 'ni-ui/icon/lib/cards/Master';
import Discover from 'ni-ui/icon/lib/cards/Discover';
import DinersClub from 'ni-ui/icon/lib/cards/DinersClub';
import ChinaUnionPay from 'ni-ui/icon/lib/cards/ChinaUnionPay';
import ApplePay from 'ni-ui/icon/lib/ApplePay';

const cardIcons = {
  VISA: Visa,
  APPLE_PAY: ApplePay,
  MASTERCARD: Master,
  AMERICAN_EXPRESS: AmericanExpress,
  DINERS_CLUB_INTERNATIONAL: DinersClub,
  JCB: Jcb,
  DISCOVER: Discover,
  CHINA_UNION_PAY: ChinaUnionPay,
  MAESTRO: Maestro,
  MADA: Mada
};

export default cardIcons;
